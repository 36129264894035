/**
 * The heading component
 */
const themeHeadingComponent = {
    baseStyle: {
    //   color: 'brand.900'
    },
    variants: {
      cursive: {
        // fontFamily: `'Shadows Into Light', sans-serif`,
        fontWeight: 'normal',
      },
      light: {
        fontWeight: 'light'
      }
    }
    };
    
    export default themeHeadingComponent;
    